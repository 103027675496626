import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/layout'),
    children: [
      { path: '', name: 'home', component: () => import('../views/home') },
      { path: 'devices', name: 'devices', component: () => import('../views/devices') },
      { path: 'accessories', name: 'accessories', component: () => import('../views/accessories') },
      { path: 'suistainable', name: 'suistainable', component: () => import('../views/suistainable') },
      { path: 'rental', name: 'rental', component: () => import('../views/rental') },
      // { path: 'webshop', name: 'webshop', component: () => import('../views/webshop') },
      { path: 'contacts', name: 'contacts', component: () => import('../views/contacts') },
      { path: 'imprint', name: 'imprint', component: () => import('../views/imprint') },
      { path: 'faq/:thema?', name: 'faq', component: () => import('../views/faq') },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    let scroll = { x: 0, y: 0 };

    if (to.hash) {
      scroll = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: 'smooth',
          });
        }, 200);
      });
    } else if (savedPosition) {
      scroll = savedPosition;
    }

    return scroll;
  },
});

export default router;
